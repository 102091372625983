<script lang="ts">
  import {getModifierClassName} from '$applib/utils/components';
  import type {ButtonModifier} from './enums';

  export let modifiers: ButtonModifier[] = [];
  export let baseClassName = 'btn';
  export let type: 'button' | 'submit' | 'reset' = 'button';
  export let disabled = false;
  export let href: string | undefined = undefined;
  let klass = '';
  export {klass as class};

  $: modifiersClassName = getModifierClassName(baseClassName, modifiers);
  $: className = [baseClassName, klass, modifiersClassName]
    .filter(Boolean)
    .join(' ');
  $: props = {...$$restProps, class: className};
</script>

{#if !href}
  {#if disabled}
    <button {disabled} {type} {...props}>
      <slot />
    </button>
  {:else}
    <button {...props} {type} on:click>
      <slot />
    </button>
  {/if}
{:else}
  <a {href} {...props} on:click>
    <slot />
  </a>
{/if}
