<script lang="ts">
  import type {SearchHit} from '$docslib/types/search';

  export let hit: SearchHit;

  function renderHtml(hit: SearchHit, attribute: string) {
    const {_highlightResult} = hit;
    const result = _highlightResult || {};
    const attrResult =  [result[attribute]].flat().find(Boolean);
    const value = attrResult ? attrResult.value : "";

    return value
  }
</script>

<a
  href={hit.url}
  class="u-padding u-fc--base u-display--block u-width--full"
  data-sveltekit-preload-data
  on:click
>
  <span class="u-display--block u-fs--h5 u-margin--smallest--block-end">
    <strong>
      {@html renderHtml(hit, 'sectionTitle')}
    </strong>
  </span>

  <span class="u-display--block u-margin--smallest--block-end">
    {@html renderHtml(hit, 'content')}
  </span>

  <span class="u-fs--micro u-fc--base-lt">
    <em>
      {hit.breadcrumbs}
    </em>
  </span>
</a>

<style>
  a:focus-visible,
  a:hover {
    background-color: rgba(102, 176, 255, 0.075);
  }
</style>
