<script lang="ts">
  import {onMount, onDestroy, afterUpdate, beforeUpdate} from 'svelte';
  import {browser} from '$app/environment';

  import algoliasearch from 'algoliasearch/lite';
  import instantsearch from 'instantsearch.js/es/index.js';
  import {pagination} from 'instantsearch.js/es/widgets';
  import {history as historyRouter} from 'instantsearch.js/es/lib/routers';

  import {connectAutocomplete} from 'instantsearch.js/es/connectors';

  import type {IndexUiState, Widget} from 'instantsearch.js';
  import type {Renderer} from 'instantsearch.js/es/types/connector';
  import type {AutocompleteRenderState} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';

  import {appConfig} from '$docslib/config';
  import {noop} from '$parentlib/utils/functions';

  import type {SearchHit} from '$docslib/types/search';

  import SearchContainer from './search-container.svelte';
  import SearchButton from './search-button.svelte';

  enum ResultsStage {
    Open,
    Closed,
  }

  enum PaginationState {
    Visible,
    Hidden,
  }

  const {algolia: algoliaConfigs} = appConfig;
  const {indexName, appId, apiKey} = algoliaConfigs;
  const searchClient = algoliasearch(appId, apiKey);
  const instantSearchRouter = browser ? historyRouter({cleanUrlOnDispose: false}) : true;
  const search = instantsearch({
    future:{preserveSharedStateOnUnmount: true},
    indexName,
    routing:
      typeof instantSearchRouter !== 'boolean'
        ? {router: instantSearchRouter}
        : instantSearchRouter,
    searchClient,
  });
  const renderAutoComplete: Renderer<
    AutocompleteRenderState,
    Record<string, never>
  > = (renderOptions, isFirstRender) => {
    const {indices, currentRefinement, refine} = renderOptions;
    const index = indices.find((item) => item.indexName === indexName) || {
      hits: [],
    };
    const {hits} = index;

    if (isFirstRender) {
      query = currentRefinement;
      refineSearch = refine;
    }

    if (!isFirstRender && hits) {
      searchHits = hits as SearchHit[];
    }
  };

  let paginationEl: HTMLDivElement;
  let searchHits: SearchHit[] = [];
  let query = getSearchUiState().query;
  let refineSearch: (query: string) => void = noop;
  let resultsState: ResultsStage = ResultsStage.Closed;
  let paginationState: PaginationState = PaginationState.Hidden;
  let paginationWidget: Widget | undefined;

  function setSearchUiState(indexUiState: IndexUiState) {
    search.setUiState((uiState) => {
      const previousState = uiState[indexName];
      let indexState = {...previousState, ...indexUiState};

      if (paginationWidget) {
        indexState = {...indexState, page: 1};
      }

      return {
        ...uiState,
        [indexName]: indexState,
      };
    });
  }

  function getSearchUiState(): IndexUiState {
    const uiState =
      typeof instantSearchRouter !== 'boolean'
        ? instantSearchRouter.read()
        : null;
    const indexState =
      uiState && uiState[indexName] ? uiState[indexName] : {query: ''};

    return indexState;
  }

  function handleQueryUpdate(event: CustomEvent<string>) {
    const {detail} = event;

    query = detail;

    setSearchUiState({query: detail});
    refineSearch(detail);
  }

  function openSearch() {
    resultsState = ResultsStage.Open;
  }

  function closeSearch() {
    resultsState = ResultsStage.Closed;
  }

  function getNextPaginationState() {
    const {mainIndex} = search;
    const results = mainIndex.getResults();
    let state =
      query &&
      query.length > 0 &&
      results &&
      results.nbHits > results.hits.length
        ? PaginationState.Visible
        : PaginationState.Hidden;

    return state;
  }

  function handleSearchShortcut(event: KeyboardEvent) {
    if (/k/i.test(event.key) && event.metaKey) {
      resultsState = ResultsStage.Open;
    }
  }

  function maybeRemovePagination() {
    const nextState = getNextPaginationState();

    if (
      paginationState === PaginationState.Visible &&
      nextState === PaginationState.Hidden &&
      typeof paginationWidget !== 'undefined'
    ) {
      search.removeWidgets([paginationWidget]);
      paginationWidget = undefined;
      paginationState = nextState;
    }
  }

  function maybeAddPagination() {
    const nextState = getNextPaginationState();

    if (
      paginationState === PaginationState.Hidden &&
      nextState === PaginationState.Visible &&
      paginationEl
    ) {
      paginationWidget = pagination({container: paginationEl});
      search.addWidgets([paginationWidget]);
      paginationState = nextState;
    }
  }

  beforeUpdate(maybeRemovePagination);
  afterUpdate(maybeAddPagination);

  onMount(() => {
    search.addWidgets([connectAutocomplete(renderAutoComplete)({})]);
    search.start();

    if (browser) {
      window.addEventListener('keydown', handleSearchShortcut);
    }
  });

  onDestroy(() => {
    if (typeof instantSearchRouter !== 'boolean') {
      instantSearchRouter.dispose();
    }

    if (browser) {
        window.removeEventListener('keydown', handleSearchShortcut);
    }
  });
</script>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://unpkg.com/instantsearch.css@7/themes/satellite-min.css"
  />
</svelte:head>

<SearchButton on:click={openSearch} />

<div data-algolia="search-results">
  {#if resultsState === ResultsStage.Open}
    <SearchContainer
      hits={searchHits}
      query={query || ''}
      on:clear={handleQueryUpdate}
      on:input={handleQueryUpdate}
      on:submit={handleQueryUpdate}
      on:close={closeSearch}
    >
      <div
        slot="pagination"
        data-algolia="search-pagination"
        bind:this={paginationEl}
      />
    </SearchContainer>
  {/if}
</div>


<!-- eslint-disable -->
<style lang="scss" global>
  .ais-Highlight-highlighted,
  .ais-Snippet-highlighted {
    background-color: rgba(102, 176, 255, 0.1);
    color: var(--struct-clr-primary);
  }
</style>
