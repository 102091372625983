<script lang="ts">
  import {onMount, createEventDispatcher, onDestroy} from 'svelte';
  import {fade, fly} from 'svelte/transition';

  import SearchResults from './search-results.svelte';
  import SearchInput from './search-input.svelte';

  import type {SearchHit} from '$docslib/types/search';

  export let hits: SearchHit[];
  export let query: string;

  const dispatch = createEventDispatcher();

  function dispatchClose() {
    dispatch('close');
  }

  function handleEscape(event: KeyboardEvent) {
    if (/escape/i.test(event.key)) {
      dispatchClose();
    }
  }

  onMount(() => {
    window.addEventListener('keyup', handleEscape);
  });
  onDestroy(() => {
    window.removeEventListener('keyup', handleEscape);
  });
</script>

<div transition:fade|global={{duration: 150}}>
  <div class="local-search-container" transition:fly|global>
    <div class="local-search__header">
      <SearchInput {query} on:input on:clear on:submit />
      <hr class="u-margin--none--block-end" />
    </div>

    <div class="local-search__results">
      <SearchResults {query} {hits} on:select={dispatchClose} />
    </div>

    <div class="local-search__footer">
      <slot name="pagination" />
    </div>
  </div>

  <div
    role="searchbox"
    tabindex="0"
    transition:fade|global={{duration: 150}}
    class="local-search-overflow"
    on:click={dispatchClose}
    on:keypress={dispatchClose}
  />
</div>

<style>
  .local-search-container {
    box-shadow: 0 8px 22px 0 rgb(37 44 97 / 15%),
      0 4px 6px 0 rgb(93 100 148 / 20%);
    left: 50%;
    max-width: 800px;
    max-height: calc(100vh - 4em);
    position: fixed;
    top: 2em;
    transform: translateX(-50%);
    width: 100%;
    z-index: 10;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .local-search__results {
    overflow: auto;
  }

  .local-search-overflow {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.2;
    z-index: 5;
  }
</style>
