import type {NavTree} from '$docslib/types/navigation';

function sortChildren(children: NavTree[]): NavTree[] {
  const childrenMap = children.reduce(
    (acc, child) => {
      const hasOrder = typeof child.order === 'number';
      const property = hasOrder ? 'withNavOrder' : 'withoutNavOrder';

      acc[property] = acc[property].concat(child);

      return acc;
    },
    {withNavOrder: [], withoutNavOrder: []} as Record<string, NavTree[]>,
  );
  const sortedChildren = [
    ...childrenMap.withNavOrder.sort((a, b) => {
      const orderA = a.order || 0;
      const orderB = b.order || 0;

      return orderA > orderB ? 1 : -1;
    }),
    ...childrenMap.withoutNavOrder.sort((a, b) => (a.title > b.title ? 1 : -1)),
  ];

  return sortedChildren;
}

export {sortChildren};
