<script lang="ts">
  import {getModifierClassName} from '$applib/utils/components';
  import {DisplayLevel} from './enums/index';

  import type {GridColModifier} from './enums/index';

  type ViewportMap = Record<
    'palm' | 'portable' | 'lap' | 'desk' | string,
    string
  >;

  export let modifiers: GridColModifier[] = [];
  export let baseWidth = '';
  export let viewportMap: ViewportMap | undefined = undefined;
  export let displayLevel: DisplayLevel | undefined = DisplayLevel.Block;

  function getVpClassNames(map?: ViewportMap) {
    const items = map
      ? Object.keys(map).map((key) =>
          getModifierClassName(baseClassName, [`${key}-${map[key]}`]),
        )
      : [];

    return items.join(' ');
  }

  const baseClassName = 'g';
  const viewportsClassname = getVpClassNames(viewportMap);
  const modifiersClassName = getModifierClassName(baseClassName, [
    baseWidth,
    ...modifiers,
  ]);
  const className = [baseClassName, modifiersClassName, viewportsClassname]
    .filter(Boolean)
    .join(' ');
</script>

{#if displayLevel === DisplayLevel.Block}
  <div class={className} {...$$restProps}>
    <slot />
  </div>
{:else}
  <span class={className} {...$$restProps}>
    <slot />
  </span>
{/if}
