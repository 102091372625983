<script lang="ts">
  import {page} from '$app/stores';

  import {
    GridWrap,
    GridWrapModifier,
    GridCol,
    GridColModifier,
  } from '$parentlib/components/grid';
  import {Wrap} from '$parentlib/components/wrap';
  import {Island, IslandModifier} from '$parentlib/components/island';

  import {SidebarNavigation} from '$docslib/components/sidebar-navigation';
  import {Search} from '$docslib/components/search';

  import {getAsset} from '$docslib/utils/assets';

  $: frontmatter = $page.data.frontmatter;
  $: nav = $page.data.navigation;
  $: seo = frontmatter.seo;
  $: title = [seo?.title, 'About Hugo Systems'].filter(Boolean).join(' | ');
</script>

<svelte:head>
  <link rel="icon" href={getAsset('favicon.png')} />

  <title>{title}</title>

  {#if seo?.description}
    <meta name="description" content={seo.description} />
  {/if}
</svelte:head>

<header class="docs-header">
  <GridWrap modifiers={[GridWrapModifier.Middle]}>
    <GridCol modifiers={[GridColModifier.Auto]}>
      <a href="/">&#8962; home</a>
    </GridCol>

    <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
      <Search />
    </GridCol>
  </GridWrap>
</header>

<GridWrap modifiers={[GridWrapModifier.NoGutter]}>
  <GridCol
    baseWidth="1-of-1"
    modifiers={[GridColModifier.ShrinkWrapDesk, GridColModifier.Tall]}
  >
    <aside class="docs-sidebar">
      <a class="u-accessibly-hidden" href="#primary-content">
        skip sidebar navigation
      </a>
      <SidebarNavigation tree={nav} minDepth={1} />
    </aside>
  </GridCol>

  <GridCol modifiers={[GridColModifier.Auto, GridColModifier.Tall]}>
    <div class="u-width--full">
      <Island modifiers={[IslandModifier.Small]}>
        <Wrap>
          <main
            class="u-padding--inline docs-content"
            id="primary-content"
            data-algolia="content"
          >
            <slot />
          </main>
        </Wrap>
      </Island>
    </div>
  </GridCol>
</GridWrap>

<style lang="scss" global>
  @import '../assets/scss/style.scss';
</style>
