<script lang="ts">
  import {getModifierClassName} from '$applib/utils/components';
  import type {IslandModifier} from './enums';

  export let modifiers: IslandModifier[] = [];
  let klass = '';
  export {klass as class};

  let baseClassName = 'island';
  let modifiersClassName = getModifierClassName(baseClassName, modifiers);

  const className = [klass, modifiersClassName || 'island']
    .filter(Boolean)
    .join(' ');
  const props = {...$$restProps, class: className};
</script>

<div {...props}>
  <slot />
</div>
