<script lang="ts">
  import {createEventDispatcher, onMount} from 'svelte';

  import {
    GridWrap,
    GridWrapModifier,
    GridCol,
    GridColModifier,
  } from '$parentlib/components/grid';

  export let query: string;

  const dispatch = createEventDispatcher();

  let inputEl: HTMLInputElement;

  function handleInput(event: Event) {
    const currentTarget = event.currentTarget as HTMLInputElement;
    const {value} = currentTarget;

    dispatch('input', value);
  }

  function handleSubmit(event: Event) {
    const currentTarget = event.currentTarget as HTMLInputElement;
    const {value} = currentTarget;

    dispatch('submit', value);
  }

  function handleClear() {
    dispatch('clear');
    focusInput();
  }

  function focusInput() {
    inputEl.focus();
  }

  onMount(focusInput);
</script>

<GridWrap modifiers={[GridWrapModifier.Middle, GridWrapModifier.NoGutter]}>
  <GridCol modifiers={[GridColModifier.Auto]}>
    <form on:submit={handleSubmit}>
      <input
        bind:this={inputEl}
        class="u-fs--h2"
        type="search"
        placeholder="Search"
        on:input={handleInput}
        value={query}
      />
    </form>
  </GridCol>

  <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
    <button class="u-btn--reset u-fc--base-lt" on:click={handleClear}
      >Clear</button
    >
  </GridCol>
</GridWrap>

<style>
  input {
    border: 0;
    padding: 1em;
    background-color: transparent;
  }

  button {
    padding: 1em 2em;
  }
</style>
