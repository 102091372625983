import type { ApplicationConfig } from "$docslib/types/config";

function getEnvValue(name: string, prefix = "VITE", fallback = "") {
	const full_name = [prefix, name].filter(Boolean).join("_");
	const value = import.meta.env[full_name]
		? `${import.meta.env[full_name]}`
		: fallback;

	return value;
}

const appConfig: ApplicationConfig = {
	algolia: {
		apiKey: getEnvValue("ALGOLIA_API_KEY"),
		appId: getEnvValue("ALGOLIA_APP_ID"),
		indexName: getEnvValue("ALGOLIA_INDEX"),
	},
};

export { appConfig };
