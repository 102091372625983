<script lang="ts">
  import {getModifierClassName} from '$applib/utils/components';
  import {DisplayLevel} from './enums';
  import type {GridWrapModifier} from './enums';

  export let modifiers: GridWrapModifier[] = [];
  export let displayLevel: DisplayLevel = DisplayLevel.Block;

  const baseClassName = 'gw';
  $: modifiersClassName = getModifierClassName(baseClassName, modifiers);
  $: className = [baseClassName, modifiersClassName].filter(Boolean).join(' ');
</script>

{#if displayLevel === DisplayLevel.Block}
  <div class={className} {...$$restProps}>
    <slot />
  </div>
{:else}
  <span class={className} {...$$restProps}>
    <slot />
  </span>
{/if}
