const getModifierClassName = <TModifier = string>(
	base: string,
	modifiers: TModifier[],
): string => {
	return modifiers
		.filter(Boolean)
		.map((m) => `${base}--${m}`)
		.join(" ");
};

export { getModifierClassName };
