import type { Load } from "@sveltejs/kit";

interface EndpointConfig {
	key: string;
	endpoint: URL;
}

interface Result {
	key: EndpointConfig["key"];
	data: Record<string, unknown>;
}

const load: Load = async function load({ route, url, fetch }) {
	const { id: routeId } = route;
	const searchParams = new URLSearchParams({ routeId: routeId || "index" });
	const configs: EndpointConfig[] = [
		{
			file: "./api/frontmatter",
			key: "frontmatter",
			transformUrl: (endpoint: URL) => {
				endpoint.search = searchParams.toString();

				return endpoint;
			},
		},
		{
			file: "./api/navigation",
			key: "navigation",
			transformUrl: (x: URL) => x,
		},
	]
		.map(({ key, file, transformUrl }) => ({
			endpoint: new URL(file, url.origin),
			key,
			transformUrl,
		}))
		.map(({ key, endpoint, transformUrl }) => ({
			endpoint: transformUrl(endpoint),
			key,
		}));
	const results = await Promise.all(
		configs
			.map(({ endpoint, key }) => {
				return {
					key,
					endpoint: endpoint.toString().replace(endpoint.origin, ""),
				};
			})
			.map(async ({ endpoint, key }) => {
				try {
					const response = await fetch(endpoint);

					if (!response.ok) {
						throw response;
					}

					const promise = new Promise<Result>((resolve) => {
						response.json().then((data) => resolve({ key, data }));
					});

					return promise;
				} catch (e) {
					const promise = new Promise<Result>((_, reject) => {
						if ((e as Response).text) {
							(e as Response).text().then((error) => reject(error));
						} else {
							reject(e);
						}
					});

					return promise;
				}
			}),
	);
	const data = results.reduce(
		(acc, { key, data }) => {
			acc[key] = data;

			return acc;
		},
		{} as Record<string, unknown>,
	);

	return {
		...data,
	};
};

export const prerender = true;

export { load };
