export enum GridColModifier {
	None = "",

	Auto = "auto",
	AutoLapAndUp = "auto--lap-and-up",
	ShrinkWrapForce = "shrink-wrap--force",
	ShrinkWrapForceLapAndUp = "shrink-wrap--force--lap-and-up",
	ShrinkWrapLapAndUp = "shrink-wrap--lap-and-up",
	ShrinkWrap = "shrink-wrap",
	ShrinkWrapDesk = "shrink-wrap--desk",
	Tall = "tall",
	OverflowXAuto = "overflow--x-auto",
}

export enum GridWrapModifier {
	Base = "",
	BlockEnd = "block-end",
	Center = "center",
	InlineEnd = "inline-end",
	Middle = "middle",
	NoGutter = "no-gutter",
	NoWrap = "nowrap",
	Small = "small",
	SpaceBetween = "space-between",
}

export enum DisplayLevel {
	Block = "block",
	InlineBlock = "inline-block",
}
