export enum ButtonModifier {
	/**
	 * colour
	 */
	ClrBase = "base",
	ClrBaseLt = "base-lt",
	ClrPrimary = "primary",

	ClrOutlinedBaseLt = "outlined--base-lt",
	ClrOutlinedPrimary = "outlined--primary",

	/**
	 * style
	 */
	Outlined = "outlined",

	/**
	 * size
	 */
	Small = "small",
}

export enum CircleButtonModifier {
	/**
	 * colour
	 */
	ClrDanger = "danger",

	/**
	 * corners
	 */
	CornerTopLeft = "corner-top-left",
	CornerTopRight = "corner-top-right",
	CornerBottomLeft = "corner-bottom-left",
	CornerBottomRight = "corner-bottom-right",
}
