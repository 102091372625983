import debounce from "just-debounce";

function noop(): void {
	//
}

function identity<T = unknown>(x: T): T {
	return x;
}

export { debounce, noop, identity };
