<script lang="ts">
  import {getModifierClassName} from '$applib/utils/components';
  import type {WrapModifier} from './enums';

  export let modifiers: WrapModifier[] = [];
  let klass = '';
  export {klass as class};

  let baseClassName = 'wrap';
  let modifiersClassName = getModifierClassName(baseClassName, modifiers);

  const className = [baseClassName, klass, modifiersClassName]
    .filter(Boolean)
    .join(' ');
  const props = {...$$restProps, class: className};
</script>

<div {...props}>
  <slot />
</div>
