<script lang="ts">
  import {createEventDispatcher} from 'svelte';
  import {fade} from 'svelte/transition';
  import {flip} from 'svelte/animate';

  import type {SearchHit} from '$docslib/types/search';

  import SearchHitItem from './search-hit.svelte';

  export let hits: SearchHit[] = [];
  export let query: string | undefined = undefined;

  const dispatch = createEventDispatcher();

  function handleSelect() {
    dispatch('select');
  }
</script>

<div class="local-search-results-container">
  {#if query && Array.isArray(hits)}
    {#if hits.length > 0}
      {#each hits as hit, i (hit.objectID)}
        <div class="local-search-results__item" animate:flip={{duration: 100}} transition:fade|global>
          {#if i > 0}
            <hr class="u-margin--none--block-end" />
          {/if}

          <SearchHitItem on:click={handleSelect} {hit} />
        </div>
      {/each}
    {:else}
      <div class="u-padding">
        We couldn't find any items matching <strong>{query}</strong>
      </div>
    {/if}
  {/if}
</div>

<style>
  .local-search-results__item {
    z-index: -1;
    background-color: #fff;
  }
</style>
