<script lang="ts">
  import {onMount} from 'svelte';
  import {afterNavigate} from '$app/navigation';
  import type {AfterNavigate} from '@sveltejs/kit';

  import {
    GridWrap,
    GridWrapModifier,
    GridCol,
    GridColModifier,
  } from '$parentlib/components/grid';

  import {sortChildren} from './utils';

  import type {NavTree} from '$docslib/types/navigation';

  enum UrlState {
    Active = 'active',
    ActiveChild = 'active-child',
    Inactive = 'inactive',
  }
  enum MenuState {
    Collapsed = 'collapsed',
    Expanded = 'expanded',
  }

  export let tree: NavTree;
  export let minDepth = 0;
  export let parentMenuState = MenuState.Expanded;

  let childTreeEl: HTMLUListElement;
  let childTreeHeight = 0;
  const indexRegex = /\+page\..+$/;

  $: children = sortChildren(tree.children || []);
  $: depth = tree.depth;
  $: currentNode =
    depth >= minDepth
      ? children.find((child: NavTree) => indexRegex.test(child.name)) || tree
      : null;
  $: childTrees = children.filter(
    (child: NavTree) => !indexRegex.test(child.name),
  );
  $: urlState = UrlState.Inactive;
  $: menuState = MenuState.Collapsed;
  $: childTreeState = [parentMenuState, menuState].some(
    (state) => state === MenuState.Expanded,
  )
    ? MenuState.Expanded
    : MenuState.Collapsed;
  $: childMenuState = [
    menuState === MenuState.Expanded,
    parentMenuState === MenuState.Expanded,
  ].every(Boolean)
    ? MenuState.Expanded
    : MenuState.Collapsed;
  $: level = depth === minDepth ? 'root' : 'child';

  function updateUrlState(navigation: AfterNavigate) {
    const {to} = navigation;
    const url = to ? to.url : new URL('');

    switch (true) {
      case currentNode && currentNode.pathname === url.pathname:
        urlState = UrlState.Active;
        break;
      case currentNode && url.pathname.startsWith(currentNode.pathname):
        urlState = UrlState.ActiveChild;
        break;
      default:
        urlState = UrlState.Inactive;
        break;
    }
  }

  function updateMenuState(navigation: AfterNavigate) {
    const {to} = navigation;
    const url = to ? to.url : new URL('');

    switch (true) {
      case currentNode && url.pathname.startsWith(currentNode.pathname):
        menuState = MenuState.Expanded;
        break;
      default:
        menuState = MenuState.Collapsed;
        break;
    }
  }

  function toggleMenuState() {
    menuState =
      menuState === MenuState.Expanded
        ? MenuState.Collapsed
        : MenuState.Expanded;
  }

  function setTreeHeight() {
    if (childTreeEl) {
      childTreeHeight = childTreeEl.scrollHeight;
    }
  }

  afterNavigate(updateUrlState);
  afterNavigate(updateMenuState);

  onMount(setTreeHeight);
</script>

<div class="docs-sidebar-navigation">
  {#if currentNode}
    <div
      class="docs-sidebar-navigation__item"
      data-css-url-state={urlState}
      data-css-menu-state={menuState}
    >
      <GridWrap
        modifiers={[GridWrapModifier.Middle, GridWrapModifier.NoGutter]}
      >
        <GridCol modifiers={[GridColModifier.Auto]}>
          <a
            class="docs-sidebar-navigation__item__link"
            data-css-level={level}
            data-css-url-state={urlState}
            data-css-menu-state={menuState}
            href={currentNode.pathname}
            data-sveltekit-preload-data
          >
            {currentNode.title || currentNode.name}
          </a>
        </GridCol>

        {#if childTrees.length > 0}
          <GridCol modifiers={[GridColModifier.ShrinkWrap]}>
            <button
              class="docs-sidebar-navigation__item__expander"
              on:click={toggleMenuState}
            >
              <span
                class="docs-sidebar-navigation__item__expander__icon"
                data-css-state={menuState === MenuState.Expanded
                  ? 'rotated'
                  : 'default'}
              />
            </button>
          </GridCol>
        {/if}
      </GridWrap>
    </div>
  {/if}

  {#if childTrees.length > 0}
    <ul
      bind:this={childTreeEl}
      class="docs-sidebar-navigation__tree"
      style="--docs-sidebar-nav-tree-max-height: {childTreeHeight}"
      data-css-state={childTreeState}
    >
      {#each childTrees as childTree}
        <li>
          <svelte:self
            tree={childTree}
            {minDepth}
            parentMenuState={childMenuState}
          />
        </li>
      {/each}
    </ul>
  {/if}
</div>
