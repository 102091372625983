<script lang="ts">
  import {Button, ButtonModifier} from '$parentlib/components/button';

  import {GridWrap, GridWrapModifier, DisplayLevel, GridCol} from '$parentlib/components/grid';
</script>

<Button modifiers={[ButtonModifier.Outlined, ButtonModifier.ClrOutlinedPrimary]} on:click>
  <GridWrap
    displayLevel={DisplayLevel.InlineBlock}
    modifiers={[GridWrapModifier.Small, GridWrapModifier.Middle]}
  >
    <GridCol displayLevel={DisplayLevel.InlineBlock}>&#128269;</GridCol>
    <GridCol displayLevel={DisplayLevel.InlineBlock}>Search</GridCol>
  </GridWrap>
</Button>
